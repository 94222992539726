import { Controller } from "@hotwired/stimulus"

const VALID_MICH_HEREINLASSEN_VALUE = "1"

export default class extends Controller {
  static targets = [ "michHereinlassen" ]

  connect() {
    // We do this instead of setting the value directly because we need js
    // enabled for our onboarding process and anyone who doesn't have js
    // enabled is a bot
    if (this.hasMichHereinlassenTarget) {
      this.michHereinlassenTarget.value = VALID_MICH_HEREINLASSEN_VALUE
    }
  }
}
