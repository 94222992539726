import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submit"]
  static values = {
    clickOnTimeout: Boolean,
    showLoadingIndicator: Boolean
  }

  connect() {
    if (this.showLoadingIndicatorValue) {
      this.startLoadingIndicator()
    }

    if (this.clickOnTimeoutValue && this.submitTarget) {
      setTimeout(() => {
        this.submitTarget.click()
      }, 2000)
    }
  }

  submit() {
    setTimeout(() => this.submitTarget.click(), 1000)
  }

  startLoadingIndicator() {
    this.loadingIndicator.style.display = "block"
  }

  get loadingIndicator() {
    return this.element.querySelector("#loading-indicator")
  }
}
