import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "timezone", "emailLogin" ]

  changeCountry(event) {
    if (this.timezoneTarget) {
      this.timezoneTarget.value = ""
    }
  }

  hideEmailLogin(event) {
    if (this.emailLoginTarget && event.target.className === "email-login-container") {
      this.emailLoginTarget.style.display = "none"
    }
  }
}
