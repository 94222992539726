import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "options", "canCall"]
  static classes = ["hide"]

  connect() {
    this.toggle()
  }

  toggle() {
    if (!this.canCallTarget.checked) {
      this.inputTarget.classList.add(this.hideClass)
      this.optionsTargets.forEach(el => el.disabled = true)

      return
    }

    this.optionsTargets.forEach(el => el.disabled = false)

    if (this.checkedOption.value != "special_instructions") {
      this.inputTarget.classList.add(this.hideClass)

      return
    }

    this.inputTarget.classList.remove(this.hideClass)
  }

  get checkedOption() {
    return this.optionsTargets.find(el => el.checked)
  }
}
