import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["location", "therapist", "charges", "quote", "month"]
  static values = {
    base: Number,
    shop: String,
    includedShops: String,
    user: String,
    includedUsers: String,
    currency: String,
    locale: String,
    countryCode: String,
    app: Number,
    weeklyPricing: Boolean
  }

  connect() {
    this.processPricing()
  }

  processPricing() {
    const locations = this.locationTarget.querySelector("input").value
    const therapists = this.therapistTarget.querySelector("input").value
    const shopValue = this.shopValue * Math.max(locations - this.includedShopsValue, 0)
    const userValue = this.userValue * Math.max(therapists - this.includedUsersValue, 0)
    if (locations >= 3 || therapists >= 6) {
      this.showQuote()
    } else {
      let base = this.baseValue + shopValue + userValue
      if (this.countryCodeValue == "GB" || this.countryCodeValue == "IE") {
        base += this.appValue
      }

      base = this.weeklyPricingValue ? (base / 4.3) : base

      this.chargesTarget.innerHTML = global.helpers.formatCurrency(base, this.currencyValue, this.localeValue)
      this.showPrice()
    }
  }

  showQuote() {
    this.quoteTarget.classList.remove("element--on")
    this.chargesTarget.classList.add("element--on")
    this.monthTarget.classList.add("element--on")
  }

  showPrice() {
    this.quoteTarget.classList.add("element--on")
    this.chargesTarget.classList.remove("element--on")
    this.monthTarget.classList.remove("element--on")
  }
}
